.privacy-policy {
  max-width: 800px;
  margin: auto;
  padding: 20px;
}

.privacy-policy h1 {
  font-size: 24px;
  margin-bottom: 10px;
}

.privacy-policy p {
  font-size: 16px;
  line-height: 1.6;
}
ul {
  list-style-type: disc;
  padding-left: 20px;
}

li {
  margin-bottom: 5px;
}
