body {
  background: url('./assets/Lavender.jpg');
  background-size: auto;
  background-position: center;
  height: 100%;
}

#root {
  height: 100%;
}

html {
  height: 100%;
}